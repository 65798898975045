export const emailRegx =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const mobileRegx = /^[2-9]{1}[0-9]{9}$/;

export const nameRegx = /^[a-zA-Z ]*$/;

export const positiveIntegerRegx = /^\+?(0|[1-9]\d*)$/;

export const bankAccountNumberRegx = /^\d{9,18}$/;

export const pincodeRegx = /^[1-9][0-9]{5}$/;

export const panRegx = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

export const ifscRegx = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/;

export const numberRegex = /^[+]?[0-9]+(\.[0-9]+)?$/;
